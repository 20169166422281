import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import mtdHeader from "../../images/mtd.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

function openMTD() {
  window.open("https://www.medieteknikdagarna.se/", "_blank")
}

const mtdPage = () => (
  <Main>
    <SEO title="Media Technology Days" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-MTD" src={mtdHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">MTD</p>
          <p class="project-header-text-grey">TREASURER</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Employment</p>
        <p class="project-bulletpoint-text"> &#9642; Non-profit</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2019-11-13</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          The Media Technology Days (MTD) is a non-profit event arranged by
          students, connecting students, working media technicians and companies
          in the industry.
          <br />
          <br />
          MTD is an opportunity for companies and students to inspire, inform
          and interact with each other. Both students and companies are given
          the chance to show the latest within media technology.
          <br />
          <br />
          The fair is arranged by a group of twelve students, where I had the
          role of a treasurer. Together with the project group, we arranged The
          Media Technology Days of 2019.
          <br />
          <br />
          You can checkout their{" "}
          <button class="project-text-link-style" onClick={() => openMTD()}>
            webpage
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.WORK}>
          &#8592; Back to work
        </a>
      </div>
    </div>
  </Main>
)

export default mtdPage
